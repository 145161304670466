import React from "react"
import { Link } from "gatsby"
import $ from "jquery"

class PricesQuestions extends React.Component {
  componentDidMount() {
    const answer = $(".questions_wrapper-answer")
    const question = ".questions_wrapper-question"
    const resultBasic = $(".questions_wrapper-result.basic")
    const resultPremium = $(".questions_wrapper-result.premium")
    const navElement = $(".questions_nav li")

    answer.click(function() {
      console.log("click")
      var answerID = $(this).attr("data-question-id")
      var answerValue = $(this).attr("data-value")

      $(`input[name='questions_value_${answerID}']`).val(`${answerValue}`)
      $(`.questions_nav li[data-id="${answerID}"]`).addClass("filled")

      if (parseInt(answerID) === 9) {
        $(`${question}[data-id='${answerID}']`).removeClass("visible")
        var sum = 0
        $(`${question} input`).each(function() {
          sum += Number($(this).val())
        })
        if (sum <= 10) {
          resultBasic.addClass("visible")
        } else {
          resultPremium.addClass("visible")
        }
      } else {
        $(`${question}[data-id='${answerID}']`).removeClass("visible")
        $(`${question}[data-id='${parseInt(answerID) + 1}']`).addClass(
          "visible"
        )
      }
    })

    navElement.click(function() {
      var navElementID = $(this).attr("data-id")
      $(".questions_wrapper-question").removeClass("visible")
      $(`.questions_wrapper-question[data-id="${navElementID}"]`).addClass(
        "visible"
      )
      $(this)
        .removeClass("filled")
        .nextAll()
        .removeClass("filled")
    })
  }

  render() {
    return (
      <div>
        <div className="prices_questions-inner">
          <h2>Dopasuj pakiet do swoich potrzeb.</h2>
          <p className="desc">
            Jeżeli nadal nie wiesz co wybrać, prosimy abyś odpowiedział na
            poniższe pytania, które pozwolą nam na zarekomendowanie Ci
            najlepszego rozwiązania „szytego na miarę”: 
          </p>
          <ul className="questions_nav">
            <li data-id="1">1</li>
            <li data-id="2">2</li>
            <li data-id="3">3</li>
            <li data-id="4">4</li>
            <li data-id="5">5</li>
            <li data-id="6">6</li>
            <li data-id="7">7</li>
            <li data-id="8">8</li>
            <li data-id="9">9</li>
          </ul>
          <div className="questions_wrapper">
            <div className="questions_wrapper-inner">
              <div className="questions_wrapper-question visible" data-id="1">
                <input type="hidden" name="questions_value_1" value="" />
                <p>Czy często miewasz problemy ze swoją stroną?</p>
                <div className="row align-items-center">
                  <div className="col-md-12 col-lg-4">
                    <div
                      className="questions_wrapper-answer"
                      data-question-id="1"
                      data-value="1"
                    >
                      Strona służy mi tylko w celach PRowych.
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-4">
                    <div
                      className="questions_wrapper-answer"
                      data-question-id="1"
                      data-value="1"
                    >
                      Strona jest dla mnie ważna, ale jeżeli przestanie działać
                      na krótki czas to nic wielkiego się nie stanie.
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-4">
                    <div
                      className="questions_wrapper-answer"
                      data-question-id="1"
                      data-value="2"
                    >
                      Moja strona to moje główne źródło klientów.
                    </div>
                  </div>
                </div>
              </div>

              <div className="questions_wrapper-question" data-id="2">
                <input type="hidden" name="questions_value_2" value="" />
                <p>Czy Twój biznes opiera się na stronie?</p>
                <div className="row align-items-center">
                  <div className="col-sm-4">
                    <div
                      className="questions_wrapper-answer"
                      data-question-id="2"
                      data-value="1"
                    >
                      Rzadko
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div
                      className="questions_wrapper-answer"
                      data-question-id="2"
                      data-value="2"
                    >
                      Czasami
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div
                      className="questions_wrapper-answer"
                      data-question-id="2"
                      data-value="2"
                    >
                      Często
                    </div>
                  </div>
                </div>
              </div>

              <div className="questions_wrapper-question" data-id="3">
                <input type="hidden" name="questions_value_3" value="" />
                <p>
                  Czy Twoja strona padła już kiedyś ofiarą ataków hakerskich?
                </p>
                <div className="row align-items-center">
                  <div className="col-sm-4">
                    <div
                      className="questions_wrapper-answer"
                      data-question-id="3"
                      data-value="1"
                    >
                      Nigdy
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div
                      className="questions_wrapper-answer"
                      data-question-id="3"
                      data-value="2"
                    >
                      Miałem problemy ze stroną, ale nie wiem czym było to
                      spowodowane
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div
                      className="questions_wrapper-answer"
                      data-question-id="3"
                      data-value="2"
                    >
                      Tak, moja strona była lub jest zawirusowana
                    </div>
                  </div>
                </div>
              </div>

              <div className="questions_wrapper-question" data-id="4">
                <input type="hidden" name="questions_value_4" value="" />
                <p>
                  Czy twoja strona ma panel zarządzania treścią w którym
                  edytujesz przedstawiane na niej treści?
                </p>
                <div className="row align-items-center">
                  <div className="col-sm-4">
                    <div
                      className="questions_wrapper-answer"
                      data-question-id="4"
                      data-value="2"
                    >
                      Nie wiem
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div
                      className="questions_wrapper-answer"
                      data-question-id="4"
                      data-value="1"
                    >
                      Nie
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div
                      className="questions_wrapper-answer"
                      data-question-id="4"
                      data-value="2"
                    >
                      Tak
                    </div>
                  </div>
                </div>
              </div>

              <div className="questions_wrapper-question" data-id="5">
                <input type="hidden" name="questions_value_5" value="" />
                <p>Jak długo masz już swoją stronę?</p>
                <div className="row align-items-center">
                  <div className="col-sm-4">
                    <div
                      className="questions_wrapper-answer"
                      data-question-id="5"
                      data-value="2"
                    >
                      Nie wiem
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div
                      className="questions_wrapper-answer"
                      data-question-id="5"
                      data-value="1"
                    >
                      1-2 lata
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div
                      className="questions_wrapper-answer"
                      data-question-id="5"
                      data-value="2"
                    >
                      Powyżej 2 lat
                    </div>
                  </div>
                </div>
              </div>

              <div className="questions_wrapper-question" data-id="6">
                <input type="hidden" name="questions_value_6" value="" />
                <p>Czy twoja strona była kiedyś aktualizowana/serwisowana?</p>
                <div className="row align-items-center">
                  <div className="col-sm-4">
                    <div
                      className="questions_wrapper-answer"
                      data-question-id="6"
                      data-value="2"
                    >
                      Nie wiem
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div
                      className="questions_wrapper-answer"
                      data-question-id="6"
                      data-value="1"
                    >
                      Nie
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div
                      className="questions_wrapper-answer"
                      data-question-id="6"
                      data-value="2"
                    >
                      Tak
                    </div>
                  </div>
                </div>
              </div>

              <div className="questions_wrapper-question" data-id="7">
                <input type="hidden" name="questions_value_7" value="" />
                <p>
                  Jak często zmieniasz treści na swojej stronie (teksty, grafiki
                  lub inne elementy)
                </p>
                <div className="row align-items-center">
                  <div className="col-sm-4">
                    <div
                      className="questions_wrapper-answer"
                      data-question-id="7"
                      data-value="2"
                    >
                      1 - 2 razy w mięsiącu
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div
                      className="questions_wrapper-answer"
                      data-question-id="7"
                      data-value="1"
                    >
                      3-5 razy w miesiącu
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div
                      className="questions_wrapper-answer"
                      data-question-id="7"
                      data-value="2"
                    >
                      Powyżej 5 razy w miesiącu
                    </div>
                  </div>
                </div>
              </div>

              <div className="questions_wrapper-question" data-id="8">
                <input type="hidden" name="questions_value_8" value="" />
                <p>
                  Czy planujesz rozwijać swoją stronę dodając kolejne treści tj.
                  artykuły, teksty, zdjęcia itp. oraz czy chciałbyś wdrożenie
                  takich zmian zlecać również nam?
                </p>
                <div className="row align-items-center">
                  <div className="col-sm-4">
                    <div
                      className="questions_wrapper-answer"
                      data-question-id="8"
                      data-value="2"
                    >
                      Nie
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div
                      className="questions_wrapper-answer"
                      data-question-id="8"
                      data-value="1"
                    >
                      Tak, planuję rozwijać stronę, ale wszystkie treści
                      zamierzam dodawać osobiście
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div
                      className="questions_wrapper-answer"
                      data-question-id="8"
                      data-value="2"
                    >
                      Tak, chciałbym zlecać komuś wdrożenie wszystkich zmian,
                      nie znam się na tym osobiście lub nie mam na to czasu
                    </div>
                  </div>
                </div>
              </div>

              <div className="questions_wrapper-question" data-id="9">
                <input type="hidden" name="questions_value_9" value="" />
                <p>
                  Czy chciałbyś otrzymać wsparcie helpdesk? Helpdesk to
                  osoba/dział pomocy z którym możesz szybko skontaktować się
                  każdego dnia roboczego w godzinach 8:00-16:00
                </p>
                <div className="row align-items-center">
                  <div className="col-sm-4">
                    <div
                      className="questions_wrapper-answer"
                      data-question-id="9"
                      data-value="2"
                    >
                      Nie
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div
                      className="questions_wrapper-answer"
                      data-question-id="9"
                      data-value="1"
                    >
                      Tak, planuję rozwijać stronę, ale wszystkie treści
                      zamierzam dodawać osobiście
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div
                      className="questions_wrapper-answer"
                      data-question-id="9"
                      data-value="2"
                    >
                      Tak, chciałbym zlecać komuś wdrożenie wszystkich zmian,
                      nie znam się na tym osobiście lub nie mam na to czasu.
                    </div>
                  </div>
                </div>
              </div>

              <div className="questions_wrapper-result basic">
                <div className="row">
                  <div className="col-sm-3">
                    <img
                      className="img-fluid"
                      src={require("../../assets/images/ico_offer_basic.svg")}
                      alt=""
                    />
                  </div>
                  <div className="col-sm-9">
                    <p>Polecamy pakiet Podstawowy</p>
                    <span>
                      Rozwiązanie przeznaczone dla stron na których treści są
                      dodawane lub aktualizowane z małą lub średnią
                      częstotliwością (do 1-2 razy w miesiącu.
                    </span>
                    <div className="row no-gutters">
                      <div className="col-auto">
                        <Link to="/" className="btn_custom">
                          &#60;Więcej o pakiecie/&#62;
                        </Link>
                      </div>
                      <div className="col-auto">
                        <Link to="/" className="btn_custom fill">
                          &#60;Przejdź do zamówienia/&#62;
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="questions_wrapper-result premium">
                <div className="row">
                  <div className="col-sm-3">
                    <img
                      className="img-fluid"
                      src={require("../../assets/images/ico_offer_basic.svg")}
                      alt=""
                    />
                  </div>
                  <div className="col-sm-9">
                    <p>Polecamy pakiet Premium</p>
                    <span>
                      Rozwiązanie przeznaczone dla stron na których treści są
                      dodawane lub aktualizowane z dużą częstotliwością 3 lub
                      więcej razy w miesiącu.
                    </span>
                    <div className="row no-gutters">
                      <div className="col-auto">
                        <Link to="/" className="btn_custom">
                          &#60;Więcej o pakiecie/&#62;
                        </Link>
                      </div>
                      <div className="col-auto">
                        <Link to="/" className="btn_custom fill">
                          &#60;Przejdź do zamówienia/&#62;
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PricesQuestions
