import React from "react"
import Modal from "react-modal"

import PricesQuestions from "./PricesQuestions"

class PricesModal extends React.Component {
  constructor() {
    super()

    this.state = {
      modalIsOpen: false,
    }

    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal() {
    this.setState({ modalIsOpen: true })
  }

  closeModal() {
    this.setState({ modalIsOpen: false })
  }

  render() {
    return (
      <section className="prices_section_2">
        {/* eslint-disable jsx-a11y/control-has-associated-label */}
        <button className="btnOpenModal" onClick={this.openModal}>
          Dopasuj pakiet do potrzeb
        </button>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          contentLabel="Example Modal"
        >
          <button className="close" onClick={this.closeModal}></button>
          <PricesQuestions />
        </Modal>
      </section>
    )
  }
}

export default PricesModal
