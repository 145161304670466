import React, { useEffect } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Accordion from "../components/Accordion"

import $ from "jquery"

import ShapePrices from "../components/shapes/ShapePrices"
import PricesContent from "../components/prices/PricesContent"
import PricesModal from "../components/prices/PricesModal"

const PricesPage = () => {
  useEffect(() => {
    function randLetter() {
      var letters = ["e", "3"]
      var letter = letters[Math.floor(Math.random() * letters.length)]
      return letter
    }
    setInterval(function() {
      $(".page_header span").html(randLetter())
    }, 500)
  })

  return (
    <Layout>
      <SEO title="Cennik" />

      <main className="prices_page">
        <ShapePrices />

        <section className="page_header_wrapper">
          <h1 className="page_header" data-text="&#60;C3nnik/&#62;">
            &#60;C<span>3</span>nnik/&#62;
          </h1>
        </section>

        <section className="prices_section_1">
          <div className="container-fluid">
            <PricesContent />
          </div>
        </section>

        <PricesModal />

        <section className="prices_section_faq">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="inner">
                  <h2>Często zadawane pytania</h2>
                  <Accordion>
                    <div
                      label="Jakie sposoby płatności są przez Państwa akceptowane?"
                      isOpen
                    >
                      <p>
                        Akceptujemy płatności kartami debetowymi oraz
                        kredytowymi.
                      </p>
                    </div>
                    <div label="Jak działają subskrypcje?">
                      <p>
                        Subskrypcja to opłata, która będzie pobierana z Twojej
                        karty/konta każdego miesiąca lub co rok, w zależności od
                        wybranego przez Ciebie okresu rozliczeniowego.
                      </p>
                    </div>
                    <div label="Jakie są dostępne okresy rozliczeniowe?">
                      <p>
                        Udostępniamy naszym Klientom dwa modele rozliczeniowe:
                      </p>
                      <ul>
                        <li>Abonament miesięczny.</li>
                        <li>Abonament roczny z rozliczeniem co roku.</li>
                      </ul>
                    </div>
                    <div label="Jak i kiedy mogę zrezygnować z subskrypcji?">
                      <p>
                        Swoją subskrypcję naszej usługi możesz anulować w
                        dowolnej chwili kontaktując się z naszym biurem obsługi
                        klienta. W takim wypadku przy następnym okresie
                        rozliczeniowym który wybrałeś nie zostanie już naliczona
                        żadna opłata, a twoje konto stanie się nieaktywne.
                      </p>
                    </div>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default PricesPage
