import React, { Component } from "react"
import PropTypes from "prop-types"

class AccordionSection extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }

  onClick = () => {
    this.props.onClick(this.props.label)
  }

  render() {
    const {
      onClick,
      props: { isOpen, label },
    } = this

    return (
      <div className="faq_question">
        {/* eslint-disable jsx-a11y/click-events-have-key-events */}
        {/* eslint-disable jsx-a11y/no-static-element-interactions */}
        <div onClick={onClick}>
          <strong className={isOpen ? "open" : "close"}>{label}</strong>
          <div>
            {!isOpen}
            {isOpen}
          </div>
        </div>
        {isOpen && <div>{this.props.children}</div>}
      </div>
    )
  }
}

export default AccordionSection
