import React from "react"
import LazyLoad from "react-lazyload"

const PricesContent = () => {
  return (
    <div className="wrapper pricing-table">
      {/* eslint-disable jsx-a11y/no-onchange */}
      <div className="row no-gutters pricing-table__holder">
        <div className="col-3 description">
          <div className="col_top">
            <p>
              Każdy z naszych pakietów zapewni Ci pełny dostęp do naszej usługi
              3w1, w ramach której zabezpieczamy, aktualizujemy, serwisujemy
              strony internetowe.
            </p>
          </div>
          <div className="col_desc">Aktualizacja i rozwój strony</div>
          <div className="col_middle col_middle-1">
            <ul className="ul_desc">
              <li>Zmieniamy zdjęcia/grafiki na nowe</li>
              <li>Edytujemy teksty</li>
              <li>Zmieniamy poszczególne elementy strony</li>
              <li>Dodajemy nowe artykuły (aktualizujemy bloga)</li>
              <li>Raportowanie</li>
              <li>Czas na rozwój strony</li>
              <li>Dodajemy/usuwamy produkty</li>
            </ul>
          </div>
          <div className="col_desc">Serwisowanie i optymalizacja</div>
          <div className="col_middle col_middle-2">
            <ul className="ul_desc">
              <li>Regularna aktualizacja silnika CMS, motywów i wtyczek*</li>
              <li>Wykrywanie uszkodzonych linków</li>
              <li>Monitoring ważności SSL</li>
              <li>Monitoring ważności domeny i hostingu</li>
              <li>Analiza Google Page Speed (miesięcznie)</li>
              <li>Optymalizacja prędkości działania serwisu</li>
            </ul>
          </div>
          <div className="col_desc">Zabezpieczenie</div>
          <div className="col_middle col_middle-3">
            <ul className="ul_desc">
              <li>Audyt bezpieczeństwa CMS (jednorazowo)</li>
              <li>Pełny backup strony (pliki i baza) (w miesiącu)</li>
              <li>Backup bazy danych (w tygodniu)</li>
              <li>Dedykowana powierzchnia na backup</li>
              <li>Skaner antywirusowy</li>
              <li>Funkcja szybkiego przywrócenia serwisu po awarii</li>
              <li>Monitoring aktywności logów</li>
              <li>Monitoring działania strony - Uptime monitoring</li>
              <li>Instancja testowa strony</li>
            </ul>
          </div>
          <div className="col_desc">Pomoc techniczna</div>
          <div className="col_middle col_middle-4">
            <ul className="ul_desc">
              <li>Obsługa zgłoszeń serwisowych</li>
              <li>
                Porady techniczne dotyczące strony lub usługi od naszych
                ekspertów
              </li>
              <li>Pomoc przez telefon i email w godzinach pracy biura</li>
              <li>Natychmiastowy dostęp do pomocy naszego programisty</li>
              <li>Pomoc z użyciem pulpitu zdalnego</li>
            </ul>
          </div>
        </div>
        <div className="col-3 basic">
          <div className="col_top">
            <div className="img_wrapper">
              <LazyLoad once>
                <img
                  className="img-fluid"
                  src={require("../../assets/images/ico_offer_basic.svg")}
                  alt=""
                />
              </LazyLoad>
            </div>
            <h4>Podstawowy</h4>
            <div className="pricing-table__buy">
              <a
                href="https://panel.forsecure.pl/user/pages/add?packet=basic&time=month"
                rel="noreferrer"
                target="_blank"
                className="pricing-table__buy pricing-table__buy--button"
              >
                399 zł miesięcznie <br />
                <span className="pricing-table__info">(netto + 23% VAT)</span>
              </a>
            </div>
            <p>
              Rozwiązanie przeznaczone dla stron na których treści są dodawane
              lub aktualizowane z małą lub średnią częstotliwością (do 1-2 razy
              w miesiącu.
            </p>
            <div className="pricing-table__buy">
              <a
                href="https://panel.forsecure.pl/user/pages/add?packet=basic&time=year"
                rel="noreferrer"
                target="_blank"
                className="pricing-table__buy pricing-table__buy--text"
              >
                3999 zł rocznie <br />{" "}
                <span className="pricing-table__info">(netto + 23% VAT)</span>
              </a>
              <small>Możesz też opłacić rok z góry (zaoszczędzisz 17%)</small>
            </div>
          </div>
          <div className="col_desc"></div>
          <div className="col_middle col_middle-1">
            <ul>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>1h</li>
              <li></li>
            </ul>
          </div>
          <div className="col_desc"></div>
          <div className="col_middle col_middle-2">
            <ul>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li></li>
              <li></li>
            </ul>
          </div>
          <div className="col_desc"></div>
          <div className="col_middle col_middle-3">
            <ul>
              <li>
                <span className="check"></span>
              </li>
              <li>4x</li>
              <li></li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li></li>
            </ul>
          </div>
          <div className="col_desc"></div>
          <div className="col_middle col_middle-4">
            <ul>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li></li>
              <li></li>
            </ul>
          </div>
          <div className="col_bottom">
            <div className="pricing-table__buy">
              <a
                href="https://panel.forsecure.pl/user/pages/add?packet=basic&time=month"
                rel="noreferrer"
                target="_blank"
                className="pricing-table__buy pricing-table__buy--button"
              >
                399 zł miesięcznie <br />
                <span className="pricing-table__info">(netto + 23% VAT)</span>
              </a>
            </div>
            <p>
              Rozwiązanie przeznaczone dla stron na których treści są dodawane
              lub aktualizowane z małą lub średnią częstotliwością (do 1-2 razy
              w miesiącu.
            </p>
            <div className="pricing-table__buy">
              <a
                href="https://panel.forsecure.pl/user/pages/add?packet=basic&time=year"
                rel="noreferrer"
                target="_blank"
                className="pricing-table__buy pricing-table__buy--text"
              >
                3999 zł rocznie <br />
                <span className="pricing-table__info">(netto + 23% VAT)</span>
              </a>
              <small>Możesz też opłacić rok z góry (zaoszczędzisz 17%)</small>
            </div>
          </div>
        </div>

        <div className="col-3 premium">
          <div className="col_top">
            <div className="img_wrapper">
              <LazyLoad once>
                <img
                  className="img-fluid"
                  src={require("../../assets/images/ico_offer_premium.svg")}
                  alt=""
                />
              </LazyLoad>
            </div>
            <h4>Premium</h4>
            <div className="pricing-table__buy">
              <a
                href="https://panel.forsecure.pl/user/pages/add?packet=premium&time=month"
                rel="noreferrer"
                target="_blank"
                className="pricing-table__buy pricing-table__buy--button"
              >
                699 zł miesięcznie <br />{" "}
                <span className="pricing-table__info">(netto + 23% VAT)</span>
              </a>
            </div>
            <p>
              Rozwiązanie przeznaczone dla stron na których treści są dodawane
              lub aktualizowane z dużą częstotliwością 3 lub więcej razy w
              miesiącu.
            </p>
            <div className="pricing-table__buy">
              <a
                href="https://panel.forsecure.pl/user/pages/add?packet=premium&time=year"
                rel="noreferrer"
                target="_blank"
                className="pricing-table__buy pricing-table__buy--text"
              >
                6999 zł rocznie <br />
                <span className="pricing-table__info">(netto + 23% VAT)</span>
              </a>
              <small>Możesz też opłacić rok z góry (zaoszczędzisz 17%)</small>
            </div>
          </div>
          <div className="col_desc"></div>
          <div className="col_middle col_middle-1">
            <ul>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>3h</li>
              <li>
                <span className="check"></span>
              </li>
            </ul>
          </div>
          <div className="col_desc"></div>
          <div className="col_middle col_middle-2">
            <ul>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
            </ul>
          </div>
          <div className="col_desc"></div>
          <div className="col_middle col_middle-3">
            <ul>
              <li>
                <span className="check"></span>
              </li>
              <li>6x</li>
              <li>1x</li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
            </ul>
          </div>
          <div className="col_desc"></div>
          <div className="col_middle col_middle-4">
            <ul>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
            </ul>
          </div>
          <div className="col_bottom">
            <div className="pricing-table__buy">
              <a
                href="https://panel.forsecure.pl/user/pages/add?packet=premium&time=month"
                rel="noreferrer"
                target="_blank"
                className="pricing-table__buy pricing-table__buy--button"
              >
                699 zł miesięcznie <br />{" "}
                <span className="pricing-table__info">(netto + 23% VAT)</span>
              </a>
            </div>
            <p>
              Rozwiązanie przeznaczone dla stron na których treści są dodawane
              lub aktualizowane z dużą częstotliwością 3 lub więcej razy w
              miesiącu.
            </p>
            <div className="pricing-table__buy">
              <a
                href="https://panel.forsecure.pl/user/pages/add?packet=premium&time=year"
                rel="noreferrer"
                target="_blank"
                className="pricing-table__buy pricing-table__buy--text"
              >
                6999 zł rocznie <br />
                <span className="pricing-table__info">(netto + 23% VAT)</span>
              </a>
              <small>Możesz też opłacić rok z góry (zaoszczędzisz 17%)</small>
            </div>
          </div>
        </div>
        <div className="col-3 ecommerce">
          <div className="col_top">
            <div className="img_wrapper">
              <LazyLoad once>
                <img
                  className="img-fluid"
                  src={require("../../assets/images/ico_offer_ecommerce.svg")}
                  alt=""
                />
              </LazyLoad>
            </div>
            <h4>E-commerce</h4>
            <div className="pricing-table__buy">
              <a
                href="https://panel.forsecure.pl/user/pages/add?packet=ecommerce&time=month"
                target="_blank"
                rel="noreferrer"
                className="pricing-table__buy pricing-table__buy--button"
              >
                799 zł miesięcznie <br />{" "}
                <span className="pricing-table__info">(netto + 23% VAT)</span>
              </a>
            </div>
            <p>
              To nasz system dedykowanych rozwiązań dla sklepów internetowych.
            </p>
            <div className="pricing-table__buy">
              <a
                href="https://panel.forsecure.pl/user/pages/add?packet=ecommerce&time=year"
                target="_blank"
                rel="noreferrer"
                className="pricing-table__buy pricing-table__buy--text"
              >
                7999 zł rocznie <br />
                <span className="pricing-table__info">(netto + 23% VAT)</span>
              </a>
              <small>Możesz też opłacić rok z góry (zaoszczędzisz 17%)</small>
            </div>
          </div>
          <div className="col_desc"></div>
          <div className="col_middle col_middle-1">
            <ul>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>3h</li>
              <li>
                <span className="check"></span>
              </li>
            </ul>
          </div>
          <div className="col_desc"></div>
          <div className="col_middle col_middle-2">
            <ul>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
            </ul>
          </div>
          <div className="col_desc"></div>
          <div className="col_middle col_middle-3">
            <ul>
              <li>
                <span className="check"></span>
              </li>
              <li>6x</li>
              <li>1x</li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
            </ul>
          </div>
          <div className="col_desc"></div>
          <div className="col_middle col_middle-4">
            <ul>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
              <li>
                <span className="check"></span>
              </li>
            </ul>
          </div>
          <div className="col_bottom">
            <div className="pricing-table__buy">
              <a
                href="https://panel.forsecure.pl/user/pages/add?packet=ecommerce&time=month"
                target="_blank"
                rel="noreferrer"
                className="pricing-table__buy pricing-table__buy--button"
              >
                799 zł miesięcznie <br />{" "}
                <span className="pricing-table__info">(netto + 23% VAT)</span>
              </a>
            </div>
            <p>
              To nasz system dedykowanych rozwiązań dla sklepów internetowych.
            </p>
            <div className="pricing-table__buy">
              <a
                href="https://panel.forsecure.pl/user/pages/add?packet=ecommerce&time=year"
                target="_blank"
                rel="noreferrer"
                className="pricing-table__buy pricing-table__buy--text"
              >
                7999 zł rocznie <br />
                <span className="pricing-table__info">(netto + 23% VAT)</span>
              </a>
              <small>Możesz też opłacić rok z góry (zaoszczędzisz 17%)</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PricesContent
